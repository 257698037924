import React from "react";
import Header from "../components/header";

import {Helmet} from "react-helmet";
import "../styles/index.css";
import {StaticImage} from "gatsby-plugin-image";

function Index() {
    return (
        <main>
            <Helmet>
                <title>IxDF Journey Mapping Course</title>
            </Helmet>

            <Header/>

            <section class="bg-ixdf">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1">
                            <h1 class="text-light">IxDF Journey Mapping Course</h1>


                            <p class="lead text-light">
                                Designed, created and shipped a 6-week course on Journey Mapping that became one of the
                                IxDF’s top 3, with <strong>over 8k students in the first 6 months. </strong></p>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2">

                            <StaticImage
                                src="../images/card-thumb-journey-mapping.png"
                                alt="Journey mapping course on an iPad"
                                placeholder="blurred"
                                className="img-fluid"
                            />

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- content --> */}

            <div className="message">Case study snapshot. Full version available by request. </div>


            <section>
                <div class="container">
                    <div class="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <h2>Situation</h2>
                            <p className="">
                                Hundreds of Members requested a Journey Mapping course, and journey mapping articles,
                                topic definitions were among our top 5 external traffic generators. </p>

                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <h2>Task</h2>
                            <p className="">
                                Designed, produced and shipped a 6-week journey mapping course.
                            </p>

                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <h2>Team/Stakeholders</h2>

                            <ul className="">
                                <li>
                                    John, Copy Editor
                                </li>
                                <li>
                                    Gustavo, Video
                                </li>
                                <li>
                                    Matt, Contributor
                                </li>
                                <li>
                                    Indi, Contributor
                                </li>
                                <li>
                                    Kai, Contributor
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <h2>Actions</h2>

                            <ul className="">
                                <li>
                                    Research—Surveys of 1,300 members, Interviews with 5 members.
                                </li>
                                <li>
                                    Definition—Course journey map, learning outcomes, contributors, budget.
                                </li>
                                <li>
                                    Design—Wrote text, assessments, exercises, video storyboards and scripts.
                                </li>
                                <li>
                                    Documentation—Rewrote editorial guidelines for new course format.
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <h2>Result</h2>
                            <p className="">
                                Shipped the course in November of 2021. It is <strong> among the 3 most popular courses on the platform with 8,000+ enrollments.</strong>
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- end content --> */}
        </main>
    );
}

export default Index;
